import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer"
import CoCurricular1 from '../components/CoCurricular1';
import Topbar from '../components/Topbar';

const CoCurricular1Page = () => {
    return (
        <Layout pageTitle="Shanti Asiatic School | InfraStructure">
            <Topbar />
            <NavOne />
            <PageHeader title="Co-curricular" />
            <CoCurricular1 />
            <Footer />
        </Layout>
    );
};

export default CoCurricular1Page;
